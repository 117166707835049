body {
    color: white;
    background-color: rgb(0, 0, 0);
    /* remove margins and scroll bars */
    margin: 0;
    overflow: hidden;
  
    /* style text */
    text-align: center;
    font-size: 12px;
    font-family: 'Black And White Picture', sans-serif;
  
    /* color text */
    color: rgb(217, 217, 217);
    padding-bottom: 50px;
  }
  
  h1 {
    /* position the heading */
    position: absolute;
    width: 100%;
  
    /* make sure that the heading is drawn on top */
    z-index: 1;
  }

  p {
    font-size: 36px;
  }

  iframe {
    border: none;
    height: auto;
  }
  
  #scene-container {
    /* tell our scene container to take up the full page */
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events:none;
    /*
      Set the container's background color to the same as the scene's
      background to prevent flashing on load
    */
    /* background-color: rgb(183, 20, 20); */
  }

  #iframeContainer {
    overflow: scroll;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  #pageContainer {
    opacity: 0;
    position: absolute;
    font-size: 58px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
  }

  .page.main.over {
    margin-top: 50px;
  }

  .centered {
    position: relative;
    margin: -25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .left {
    text-align: left;
  }

  .player-container {
    background-size: cover;
    width: 100%;
    height: 100%;
  }
